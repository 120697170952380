<template>
  <div class="mainBox">
    <div class="mainauto">
      <p class="title">服务网络</p>
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="12"
          ><div class="grid-content bg-purple">
            <div class="leftTop"></div>
            <div class="leftBottom">
              <div class="leftBottomMain">
                <strong>深圳联络点：</strong
                ><br />联系人：李先生<br />电话：13316803380<br />地址：深圳市宝安区福永街道展城社区和秀西路久阳工业园7栋4楼
              </div>
              <div class="leftBottomMain">
                <strong>太原联络点：</strong
                ><br />联系人：高先生<br />电话：16635012345<br />地址：山西省太原市小店区中环新天地一单元2301
              </div>
              <div class="leftBottomMain">
                <strong>合肥联络点：</strong
                ><br />联系人：肖先生<br />电话：19965000588<br />地址：安徽省合肥市高新区明珠大道与香樟大道交口东北角太平洋森活城B-1#楼12-办公室14室
              </div>
              <div class="leftBottomMain">
                <strong>苏州联络点：</strong
                ><br />联系人：魏先生<br />电话：18151118530<br />地址：苏州市姑苏区城北西路1958号，南山金城66幢1503
              </div>
            </div>
          </div></el-col
        >
        <el-col :span="11"
          ><div class="grid-content bg-purple-light">
            <div
              id="map"
              style="width: 600px; height: 600px"
              ref="echarts"
            ></div></div
        ></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// 引入echarts
import echarts from "echarts";
// 引入地图
import "echarts/lib/chart/map";
// 引入js
import "echarts/map/js/china.js";
export default {
  name: "service-network",
  mounted() {
    this.init();
  },

  methods: {
    init() {
      let myChart = echarts.init(this.$refs.echarts);
      let option = {
        tooltip: {
          // 鼠标移到图里面的浮动提示框
          formatter(params) {
            let city, people, phone, address;
            if (params.data) {
              city = params.data.city;
              people = params.data.people;
              phone = params.data.phone;
              address = params.data.address;

              let htmlStr = `
            
                          <div style='font-size:18px;'> ${params.name}</div>
                          <p style='margin-top:10px;'>
                                 <strong>${city}联络点：</strong
                ><br />联系人：${people}<br />电话：${phone}
                          </p>
                          <p style='width:160px;white-space: pre-wrap;'>地址：${address}</p>
                       `;
              return htmlStr;
            } else {
              let nohtmlStr = `
                          <div style='font-size:18px;'> ${params.name}</div>
                          <p style='text-align:left;margin-top:10px;'>
                                 <strong>联络点：无</strong>
              
                          </p>
                        `;
              return nohtmlStr;
            }
          },
        },

        geo: {
          map: "china",
          show: true,
          roam: true,
          top: 0,
          label: {
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              borderColor: "#c7d6fc",
              borderWidth: 2,
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: "#bfd9ff", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#c8f7fe ", // 100% 处的颜色
                  },
                ],
                globalCoord: true, // 缺省为 false
              },
            },
            emphasis: {
              areaColor: "#c7d6fc",
            },
          },
        },
        series: [
          {
            name: "",
            type: "map",
            geoIndex: 0,
            label: {
              show: true,
            },

            data: [
              {
                name: "广东",
                city: "深圳",
                people: "李先生",
                phone: "13316803380",
                address: "深圳市宝安区福永街道展城社区和秀西路久阳工业园7栋4楼",
              },
              {
                name: "山西",
                city: "太原",
                people: "高先生",
                phone: "16635012345",
                address: "山西省太原市小店区中环新天地一单元2301",
              },
              {
                name: "安徽",
                city: "合肥",
                people: "肖先生",
                phone: "19965000588",
                address:
                  "安徽省合肥市高新区明珠大道与香樟大道交口东北角太平洋森活城B-1#楼12-办公室14室",
              },
              {
                name: "江苏",
                city: "苏州",
                people: "魏先生",
                phone: "18151118530",
                address: "苏州市姑苏区城北西路1958号，南山金城66幢1503",
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style scoped>
.mainBox {
  overflow: hidden;
  padding-bottom: 180px;
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 38px;
  color: #404040;
  padding: 120px 0 60px;
  text-align: center;
}
.leftTop {
  width: 100%;
  height: 38px;
  background: #c7d6fc;
}
.leftBottom {
  background: #f6f6f6;
  padding: 28px 36px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.leftBottom .leftBottomMain {
  background: #fff;
  width: calc(100% / 2 - 10px);
  height: 219px;
  margin-bottom: 28px;
  padding: 21px;
  line-height: 26px;
  color: #6a6a6a;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2px;
}
.leftBottom .leftBottomMain strong {
  color: #333;
  font-size: 15px;
}
#map {
  padding-top: 100px;
}
</style>